<template>
    <Dialog title="添加员工" width="50%" :visible="inviteDialogShow" class="dialog-box f-cl-c" @closeDialog="closeDialog">
        <div>
            <div class="f-c">
                <PhoneInput :modelValue="searchEmployeePhone" :placeholderValue="'请输入手机号搜索用户'"
                    :prefixIcon="'el-icon-search'" @getPhoneNumber="getPhoneNumber" @searchEmployee="searchEmployee">
                </PhoneInput>
                <el-button type="primary" style="margin-left:30px" @click="searchEmployee">搜索</el-button>
            </div>
            <div class="no-list" v-if="inviteEmployeeList && inviteEmployeeList.length">
                <div class="f-c-b invite-item" v-for="(item, index) of inviteEmployeeList" :key="index">
                    <div class="f-c">
                        <el-image class="item-image" :src="item.userAvatarUrl" fit="fill"></el-image>
                        <div class="f-cl baseInfo">
                            <span>{{ item.userName }}</span>
                            <span>{{ item.phone }}</span>
                        </div>
                    </div>
                    <el-button type="success" class=" invite-btn" @click="inviteAdd(item.userId)">邀请加入</el-button>
                </div>
            </div>
            <el-empty v-else description="暂无信息！" class="no-list"></el-empty>
        </div>
    </Dialog>
</template>

<script>
export default {
    name: '',
    props: {
        inviteDialogShow: {//是否显示邀请盒子
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            searchEmployeePhone: '',
            inviteEmployeeList: [],
            invitePage: 1,
            inviteSize: 20
        }
    },
    created() {
    },
    methods: {
        getPhoneNumber(data) {
            this.searchEmployeePhone = data.value
        },
        // 企业根据手机号查找用户接口
        searchEmployee() {
            let that = this,
            phone = that.searchEmployeePhone;
            if(phone.length<11){
                return that.$common.message('请输入正确的手机号!', 'error')
            }
            that.$http.findByPhoneUser({
                applyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                phone,
                page: that.invitePage,
                size: that.inviteSize
            }).then(res => {
                if (res.data.records.length == 0) {
                    that.$common.message('搜索不到该用户!', 'error')
                } else {
                    this.inviteEmployeeList = this.inviteEmployeeList.concat(res.data.records)
                    this.inviteTotalPage = res.data.pages
                    this.inviteCurrentPage = res.data.current
                }

            })

        },
        // 企业邀请用户接口
        inviteAdd(targetUserId) {
            let that = this;
            that.$confirm('确定邀请加入', '提示').then(() => {
                that.$http.enterpriseInviteUser({
                    applyUserId: parseInt(that.$store.state.userId),
                    token: that.$getStorage('token'),
                    targetUserId,
                    enterpriseId: that.$getStorage('enterpriseStateInfo').enterpriseId
                }).then(res => {
                    if (res.code == 275) {
                        that.$common.message(res.msg, 'success', 1500)
                        setTimeout(() => {
                            that.closeDialog()
                        }, 1500)
                    } else {
                        that.$common.message(res.msg, 'success')
                        that.closeDialog()
                    }
                })
            }).catch(() => { })
        },

        closeDialog() {
            this.inviteEmployeeList = []
            this.searchEmployeePhone = ''
            this.$emit('closeInviteDialog', false)
        }
    },
}

</script>
<style lang='less' scoped>
.dialog-box {

    .invite-item {
        padding: 10px 20px;
        border-bottom: 1px solid #ddd;


        .item-image {
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }

        .baseInfo {
            margin-left: 30px;

            span {
                font-size: 14px;
            }

            span:first-child {
                font-size: 18px;
            }

            span:nth-child(2) {
                margin-top: 15px;
            }
        }

        .invite-btn {
            border-radius: 20px;
        }
    }

    .no-list {
        height: 350px;
        margin-top: 30px;

        /deep/ .el-empty {
            padding: 0;
        }
    }
}
</style>
